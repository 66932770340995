
/* You can add global styles to this file, and also import other style files */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Sarabun", sans-serif;
}


hr {
  color: #1364ff;
  height: 2px;
  margin-top: 8px;
  margin-bottom: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Sarabun", sans-serif;
  font-size: 14px;
  background-color: rgb(221, 236, 242);
  margin: 0;
}

.homsy-logo{
  position: absolute;
    width: 35%;
    left: 40px;
    top: 35px;
}

.homsy-container {
    border-radius: 1rem;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .1), 0 10px 10px -5px rgba(0, 0, 0, .04)!important;
}

.titulo {
  font-family: "Sarabun", sans-serif;
  font-size: 1.3rem;
  font-weight: 800;
  margin: 3rem 2rem;
  text-align: center;
}

.subtitulo {
  font-size: 1rem;
  margin: 1.5rem;
  font-weight: 400;
  font-family: "Sarabun", sans-serif;
  text-align: left;
}

.hidden-div {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 8rem 0px 0px;
}

.btn-done button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  width: 40%;
  font-family: "Sarabun", sans-serif;
}

.buttons {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 2rem;
}

.shadow {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.homsy-btn {
  align-items: center !important;
  border-radius: 0.5rem !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 1rem !important;
  width: 10rem !important;
  height: 10rem !important;
  margin: 1rem !important;
}

.homsy_selected, .homsy-btn:hover{
  background-color: #1364ff;
  color: white;
}

.homsy_selected img, .homsy-btn:hover img {
  /* filter: invert(1); */
  filter: brightness(0) invert(1);
}

/* UPLOAD PHOTO STYLES*/
.foto {
  position: relative;
  background-color: #DDECF2;
  width: 85%;
  height: 270px;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: left;
  overflow-y: auto;
}

.foto .titulo {
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding-top: 20px;
  color: #90949C
}

.foto .camara {
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  filter: invert(1);
  position: absolute;
  left: 0;
  right: 0;
}

.alinear-fotos {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5rem;
}

.tiny-camera {
  width: 55px;
  filter: invert(1);
}

.fotos-item {
  /* background-color: rgba(19,100,255,0.5); */
  width: 120px;
  height: 120px;
  border-radius: 6px;
  margin-left: 10px;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.foto-img-item{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.remove-photo{
  position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #1364ff;
    color: white;
    font-weight: bold;
    margin-right: -10px;
    margin-top: -10px;
    cursor: pointer;
}

.remove-photo:hover{
  background: #3773e6;
}

.foto-titulo{
  position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    padding: 0;
    margin: 0;
    font-family: "Sarabun", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #90949C;
}

.btn-icon{
  margin-top: -3px;
}
/* END UPLOAD PHOTO STYLES*/

.blue-title {
  margin-bottom: 1rem;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  font-family: "Sarabun", sans-serif;
  color: #42a8da;
}

.center{
  text-align: center;
}

.btn-rounded{
  background-color: rgba(19,100,255,1) !important;
    border-radius: 25px !important;
    margin-bottom: 1rem !important;
    padding: 0 40px 0 !important;
}

.homsy-btn{
  width: 100px;
  height: 110px;
  padding: 0.6rem 1rem 0.6rem 1rem;
      margin-right: 10px;
}

.homsy-btn-small{
  width: 6.5rem;
  height: 7rem;
  padding: 0.6rem 1rem 0.6rem 1rem;
      margin-right: 10px;
}

.homsy-btn img,.homsy-btn-small img  {
  height: 80%;
  width: auto;
}


.homsy-btn span,.homsy-btn-small span{
  margin-bottom: -1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.mat-button-wrapper {
  position: relative !important;
    width: 100%;
    height: 100%;
}

.mat-dialog-container {
  /* padding: 0!important; */
}

.mat-checkbox-layout .mat-checkbox-label {
  white-space: break-spaces;
  line-height: 19px;
}

.inline-block{
  display: inline-block !important;
}
/* .mat-icon-button .mat-button-wrapper {
  display: flex;
  justify-content: center;
} */


.homsy-item-selected{
  background-color: #1364ff !important;
  color: white !important;
}
.homsy-item-selected img {
  /* filter: invert(1) !important; */
  filter: brightness(0) invert(1) !important;
}

 .homsy-item-selected .controles, .homsy-item-selected .subtitle-item, .homsy-item-selected .controles>span{
  color: white !important;
}

.cart-btn{
  position: fixed !important;
  top: 15px;
  right: 20px;
}

.table-fotos-list{

}

.comma:not(:last-child):after {
  content: ", ";
}


.img-article {
  width: 3rem;
  display: block;
  margin-left: 0.8rem;
  margin-right: 1.5rem;
}

.boton-article {
  align-items: center;
  border-radius: 0.5rem !important;
  display: flex !important;
  flex-direction: column;
  padding: 1rem !important;
  width: 6.5rem;
  height: 7rem;
  margin: 1rem !important;
}

button span{
  color: #1364ff;
}

.homsy_selected span{
  color: white;
}
.no-hover-btn:hover span{
  color: #1364ff;
}

.homsy_selected span{
  color: white !important;
}

.button-white-label span{
  color:white;
}

.no-hover-btn span{
  color: #1364ff;
}

.login-btn span{
  color:white;
}

.login-cancel-btn span{
  color:#1364ff !important;
}

.controles{
  position: relative;
}

.counter-label{
  margin: auto;
}

.add-item-btn{
  background: url('./assets/icons/add.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  right: 0;
}

.remove-item-btn{
  background: url('./assets/icons/remove.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;
  left: 0;
}

.homsy-item-selected .add-item-btn, .homsy-item-selected .remove-item-btn{
  filter: brightness(0) invert(1);
}

.hogar-selected .add-item-btn, .hogar-selected .remove-item-btn{
  filter: brightness(0) invert(1);
}

.no-change-btn span{
  color: white !important;
}

.no-change-blue-btn span{
  color: #1364ff !important;
}

.add-cart-btn span{
  color: #1364ff !important;
}

.remove-cart-btn span{
  color: #1364ff !important;
}

.boton-article img{
  width: 3rem;
  display: block;
  margin-left: 0.8rem;
  margin-right: 1.5rem;
}

.boton-article span{
  white-space: break-spaces;
  line-height: 13px;
}

.step-by-step{
  position: absolute;
  width: 100%;
  height: 50px;
  margin-top: 130px;
  margin-left: -1rem;
}

.step-by-step-line{
  width: 80%;
  height: 7px;
  background: #cddfff;
  border-radius: 50px;
  margin: auto;
  margin-top: 25px;
}

.step-by-step-number{
  position: absolute;
  top: 10px;
  height: 35px;
}

.step-by-step-one{
  left: 15%;
}

.step-by-step-two{
  left: 35%;
}

.step-by-step-third{
  left: 55%;
}

.step-by-step-four{
  left: 75%;
}

.cotizadores-top-img {
  position: absolute;
  right: -5rem;
  top: -8rem;
  width: 25rem;
}

@media (max-width: 590px) {
  .cotizadores-top-img {
    top: -7rem;
    width: 23rem;
  }
}

@media (max-width: 535px) {
  .cotizadores-top-img {
    top: -5.5rem;
    width: 20rem;
  }
}

@media (max-width: 462px) {
  .cotizadores-top-img {
    top: -4.3rem;
    width: 17rem;
  }
}

@media (max-width: 388px) {
  .cotizadores-top-img {
    top: -3.3rem;
    width: 15rem;
  }
}

.btn-spinner-pos{
    position: absolute;
    top: -14px;
    margin-left: -4px;
    z-index: 9;
    color: #ffffff;
}

.to-left{
    text-align: left !important;
    margin-left: 0;
}

.hidden{
  visibility: hidden;
  opacity: 0;
}

.speech-bubble{
  /* layout */
	position: fixed;
	max-width: 30em;
	right: 75px;
  top: 10px;
  transition: all 0.5s !important;
	/* looks */
	background-color: #fff;
	padding: 1.125em 1.5em;
	font-size: 1.25em;
	border-radius: 1rem;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.speech-bubble::before {
/* layout */
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	/* bottom: 100%; */
	/* left: 1.5em; // offset should move with padding of parent */
  right: -17px;
  transform: rotate(90deg);
	border: .75rem solid transparent;
	border-top: none;

	/* looks */
	border-bottom-color: #fff;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}

.form-user-comp mat-dialog-container{
  padding: 0 !important;
}

.whatsapp-btn{
  position: fixed !important;
  background-color: #40c351 !important;
  bottom: 20px;
  right: 20px;
}

.whatsapp-popup{
    z-index: 999999998;
    background: red;
    height: 318.5px;
    width: 351px;
    position: fixed;
    right: 15px;
    bottom: 90px;
    border-radius: 5px;
    box-shadow: 0 10px 10px 4px rgb(185 183 183 / 54%);
    -webkit-box-shadow: 0 10px 10px 4px rgb(185 183 183 / 54%);
    -moz-box-shadow: 0 10px 10px 4px rgb(185 183 183 / 54%);
    transition: all 0.5s;
}

.whatsapp-title{
    background: #2db742;
    color: #d9ebc6;
    padding: 15px 43px 17px 74px;
    position: relative;
    height: 146px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.whatsapp-content{
    background: white;
    position: relative;
    height: 172.5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.whatsapp_heading::before{
      background: url(./assets/icons/whatsapp-96.png) top no-repeat;
    background-size: 36px;
    content: "";
    display: block;
    height: 36px;
    left: 12px;
    position: absolute;
    top: 20px;
    width: 55px;
}

.whatsapp_title{
    font-size: 18px;
    line-height: 24px;
    padding-top: 2px;
}

.whatsapp_intro{
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
}

.whatsapp-msg{
      padding: 10px;
    margin-left: 10px;
    color: gray;
    font-size: small;
}

.whatsapp-contact-btn{
    width: 312px;
    height: 73px;
    margin: auto;
    margin-top: 18px;
    background: #f5f7f9;
    border-left: 2px solid #2db742;
    transition: all 0.3s ease;
}

.whatsapp-contact-btn:hover{
  background: white;
      box-shadow: 0 7px 15px 1px rgb(55 62 70 / 7%);
    -webkit-box-shadow: 0 7px 15px 1px rgb(55 62 70 / 7%);
    -moz-box-shadow: 0 7px 15px 1px rgba(55,62,70,.07);
}
.ws_firstIcon{
    width: 55px;
    position: absolute;
    margin-left: 10px;
    top: calc(50% - 27.5px);
}

.ws_secondIcon{
    width: 20px;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
}

.ws_hiperlink{
    text-decoration: none;
    color: black;
}

.ws_hiperlink:hover{
    text-decoration: none;
    color: black;
}

 .ws_btn_title{
    position: absolute;
    left: 0;
    margin-left: 80px;
    top: 24px;
    font-size: 15px;
 }

 /*Modal carrito*/

 .cart-dialog{
    max-width: 420px !important;
    width: 420px;
 }
 
 @media (max-width:485px){
.cart-dialog{
    max-width: 95% !important;
    width: 95%;
 }
}

 .cart-dialog mat-dialog-container{
   padding: 14px;
 }

 .cart-dialog mat-dialog-content {
   padding-left: 8px !important;
   padding-right: 8px !important;
 }
